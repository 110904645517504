import {devLogger} from "../util/loggingUtils";

export interface Config {
    paymentEdgeHost: string,
    pnsRedirectUrl: string | undefined,
    nwRedirectUrl: string | undefined,
    pnsPropertyId: string | undefined,
    nwPropertyId: string | undefined,
    gaUrl: string | undefined,
    isDevelopment: boolean,
    appVersion: string,
    gitHash: string,
    cardStatusDelay: number,
    directPostProxy: string

}

let config: Config;

export const initialiseConfig = () => {
    config = {
        paymentEdgeHost: window?._env_?.PAYMENT_API_URL || 'payment-api.local.test',
        pnsRedirectUrl: window?._env_?.REDIRECT_URL_PNS,
        nwRedirectUrl: window?._env_?.REDIRECT_URL_NW,
        directPostProxy: window?._env_?.DIRECT_POST_PROXY || "",
        pnsPropertyId: window?._env_?.GA_PROPERTY_PNS,
        nwPropertyId: window?._env_?.GA_PROPERTY_NW,
        gaUrl: window?._env_?.GA_URL,
        isDevelopment: process.env.NODE_ENV === "development",
        appVersion: process.env.REACT_APP_VERSION,
        gitHash: process.env.REACT_APP_GIT_HASH,
        cardStatusDelay: 4000
    } as Config;
    if (config.isDevelopment) {
        devLogger("Current config: ");
        devLogger(JSON.stringify(config));
    }
}

export const getConfiguration = () : Config => {
    if (!config) {
        initialiseConfig();
    }
    return config;
}