import React, {useEffect, useState, useCallback} from "react";
import {Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import {CardType} from "../model/CardType";
import Button from "react-bootstrap/Button";
import SpinnerButton from "./SpinnerButton";
import {
    handleCard,
    handleCvv,
    handleExpiry,
    isValidCardNumber,
    isValidCVV,
    isValidExpiry, isValidName,
} from "../util/cardUtils";
import {useConfig} from "../context/configContext";

const RegisterCardForm = ({handleSubmit, postUrl, processingCard, tokenExpired}) => {
    const [cardState, setCardState] = useState<any>({
        expiry: "",
        cardNumber: "",
        cardNumberDisplay: "",
        expiryMonth: "",
        expiryYear: "",
        inputKey: 0,
        cvv: "",
        cardName: "",
        invalid: false,
        invalidCreditCard: false,
        invalidExpiry: false,
        invalidCvv: false,
        invalidName: false,
        cardType: null,
    });
    const [addCardDisabled, setAddCardDisabled] = useState(true);

    const isAddCardDisabled = useCallback(() => {
        let disabled = true;
        if (cardState.cardNumber !== "" &&
            cardState.expiryMonth !== "" &&
            cardState.expiryYear !== "" &&
            cardState.cvv !== "" &&
            cardState.cardName !== "") {
            disabled = processingCard ||
                !postUrl ||
                tokenExpired ||
                cardState.invalidCreditCard ||
                cardState.invalidExpiry ||
                cardState.invalidCvv ||
                cardState.invalidName
        }
        return disabled;
    }, [cardState, postUrl, processingCard, tokenExpired])

    useEffect(() => {
        if (cardState.cardNumber !== "" &&
            cardState.expiryMonth !== "" &&
            cardState.expiryYear !== "" &&
            cardState.cvv !== "" ) {
            setAddCardDisabled(isAddCardDisabled());
        }

    }, [cardState, isAddCardDisabled]);

    const {  handleRedirect } = useConfig();

    const updateCard = (event) => {
        const inputCard = event.target.value;
        const newCard = handleCard(cardState.cardNumber, cardState.cardNumberDisplay, inputCard, cardState.inputKey);
        const cardNumberIsValid = isValidCardNumber(newCard.cardNumber);
        setCardState({
            ...cardState,
            ...newCard,
            invalidCreditCard: !cardNumberIsValid
        });
        setAddCardDisabled(isAddCardDisabled());
    }

    const updateExpiry = (event) => {
        const inputExpiry = event.target.value;
        const newExpiry = handleExpiry(cardState.expiryMonth, cardState.expiryYear, cardState.expiry, inputExpiry, cardState.inputKey);
        const cardExpiryIsValid = isValidExpiry(newExpiry.expiryMonth, newExpiry.expiryYear);
        setCardState({
            ...cardState,
            ...newExpiry,
            invalidExpiry: !cardExpiryIsValid
        });
        setAddCardDisabled(isAddCardDisabled());
    }

    const updateCvv = (event) => {
        const inputCvv = event.target.value;
        const newCvv = handleCvv(cardState.cvv, inputCvv)
        const cVVIsValid = isValidCVV(cardState.cardNumber, inputCvv)
        setCardState({
            ...cardState,
            cvv: newCvv,
            invalidCVV: !cVVIsValid
        });
        setAddCardDisabled(isAddCardDisabled());
    }

    const updateName = (event) => {
        let inputName = event.target.value;
        inputName = inputName.toUpperCase();
        const nameIsValid = isValidName(inputName);
        event.target.value = inputName;
        setCardState({
            ...cardState,
            cardName: inputName,
            invalidName: !nameIsValid
        });
        setAddCardDisabled(isAddCardDisabled());
    }

    return(
        <>
            <Form className="cardForm" method="POST" action={postUrl} onSubmit={handleSubmit} noValidate>
                <Form.Group controlId="formGridCard">
                    <Form.Label className="left">Card number</Form.Label>
                    <Form.Control onChange={updateCard}
                                  onBlur={updateCard}
                                  value={cardState.cardNumberDisplay}
                                  maxLength={20}
                                  type="tel"
                                  onKeyDown={(event) => {
                                      setCardState({...cardState, inputKey: event.keyCode})
                                  }}
                                  isInvalid={cardState.invalidCreditCard} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid credit card number
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Control type="hidden" name="card_number" value={cardState.cardNumber}/>

                <Form.Group as={Row}>
                    <Form.Group as={Col} md="6" controlId="formGridExpiry" style={{padding: 'inherit'}}>
                        <Form.Label>Expiry date (MM/YY)</Form.Label>
                        <Col className="expiryDate">
                            <Form.Control name="card_expiry" type="tel"
                                          onChange={updateExpiry}
                                          onBlur={updateExpiry}
                                          onKeyDown={(event) => {
                                              setCardState({...cardState, inputKey: event.keyCode})
                                          }}
                                          value={cardState.expiry}
                                          placeholder="MM/YY"
                                          isInvalid={cardState.invalidExpiry}
                                          maxLength={5}/>
                            <Form.Control type="hidden" name="card_expiry_month" value={cardState.expiryMonth}/>
                            <Form.Control type="hidden" name="card_expiry_year" value={cardState.expiryYear}/>
                            <Form.Control.Feedback type="invalid">
                                Please enter a valid expiry date
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="formGridCvv" style={{padding: 'inherit'}}>
                        <OverlayTrigger
                            placement="top"
                            delay={{ show: 250, hide: 400 }}
                            transition={false}
                            overlay={(props) => (
                                <Tooltip id="button-tooltip" {...props}>
                                    3-digit security code usually found on the back of your card. American Express cards have a 4-digit code located on the front
                                </Tooltip>
                            )}>
                            {({ ref, ...triggerHandler }) => (
                                <span {...triggerHandler} >
                                    <Form.Label>CVV code &nbsp;</Form.Label>
                                    <div className="info" data-toggle="tooltip" ref={ref}>
                                        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="info-circle"
                                             className="svg-inline--fa fa-info-circle fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg"
                                             viewBox="0 0 512 512">
                                            <path fill="#571060"
                                                  d="M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z"/>
                                        </svg>
                                    </div>
                                </span>
                            )}
                        </OverlayTrigger>
                        <Form.Control disabled={cardState.cardType === CardType.DINERS_CLUB}
                                      name="card_csc"
                                      maxLength={4}
                                      onChange={updateCvv}
                                      onBlur={updateCvv}
                                      value={cardState.cvv}
                                      isInvalid={cardState.cardType !== CardType.DINERS_CLUB && cardState.invalidCvv}
                                      placeholder="Enter CVV"/>
                        <Form.Control.Feedback type="invalid">
                            Please enter a valid CVV
                        </Form.Control.Feedback>
                    </Form.Group>
                </Form.Group>

                <Form.Group controlId="formGridName">
                    <Form.Label>Name on card</Form.Label>
                    <Form.Control name="card_holder_name"
                                  onChange={updateName}
                                  onBlur={updateName}
                                  placeholder="Enter Name"
                                  isInvalid={cardState.invalidName} />
                    <Form.Control.Feedback type="invalid">
                        Please enter a valid name
                    </Form.Control.Feedback>
                </Form.Group>

                <div className="mt-4 mb-4 center">
                    {!processingCard &&
                        <Button id="btnAddCard"
                                disabled={addCardDisabled}
                                variant="primary"
                                type="submit"
                                className="px-5 font-weight-bold">
                            Add card
                        </Button>
                    }
                    {processingCard &&
                        <SpinnerButton variant="primary" text="Loading..." />

                    }
                </div>
                <div className="center">
                    <Button variant="secondary"
                            className="px-5 font-weight-bold"
                            style={{fontSize: '18px'}}
                            onClick={() => handleRedirect(true)}>
                        Cancel
                    </Button>
                </div>
            </Form>
        </>
    );
}

export default RegisterCardForm