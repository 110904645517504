import {isValid} from "luhn-js";
import {CardType} from '../model/CardType';
import {CardValidation} from '../model/CardValidation';

export const getCardDisplay = (maskedNumber) => {
 return `ending in ${maskedNumber ? maskedNumber.substring(maskedNumber.length - 4) : ''}`;
}

export const getExpiryDisplay = (expiryDate) => {
 const expirySplit = expiryDate.split('-');
 return `${expirySplit[1]}/${expirySplit[0].substring(2)}`;
}

export const handleCvv = (oldCvv, inputCvv) => {
   const cardPattern = /^[0-9\b]+$/;

   if (inputCvv.length > 0) {
       if (cardPattern.test(inputCvv)) {
           return inputCvv;
       } else {
           return oldCvv;
       }
   } else {
       return inputCvv;
   }
}

export const handleExpiry = (oldMonth, oldYear, oldExpiry, inputExpiry, inputKey) => {
     const expiryPattern = /^[0-9\b/]+$/;

     if (inputExpiry.length > 0) {
         if (!expiryPattern.test(inputExpiry)) {
            return {
                expiry: oldExpiry,
                expiryMonth: oldMonth,
                expiryYear: oldYear,
             };
         }

         if (inputExpiry.indexOf("/") > -1 && inputExpiry.length < 3) {
             return {
                 expiry: oldExpiry,
                 expiryMonth: oldMonth,
                 expiryYear: oldYear,
              };
         }
     }

     if (inputExpiry && inputExpiry.length === 2) {
         if (inputKey === 8) {
             return {
                  expiry: inputExpiry.substr(0, 1),
                  expiryMonth: oldMonth,
                  expiryYear: oldYear,
               };
         } else {
             return {
               expiry: inputExpiry + "/",
               expiryMonth: oldMonth,
               expiryYear: oldYear,
            };
         }
     } else if (inputExpiry && inputExpiry.length > 2 ) {
         let displayExpiry = inputExpiry.replace(/\//g, "");
         displayExpiry = displayExpiry.substr(0, 2) + "/" + displayExpiry.substr(2);

         if(displayExpiry.length === 5) {
             return {
                expiry: displayExpiry,
                expiryMonth: displayExpiry.slice(0, 2),
                expiryYear: displayExpiry.slice(3),
             };
         } else {
             return {
                 expiry: displayExpiry,
                 expiryMonth: oldMonth,
                 expiryYear: oldYear,
              };
         }
     } else {
         return {
          expiry: inputExpiry,
          expiryMonth: oldMonth,
          expiryYear: oldYear,
       };
     }
}

export const handleCard = (oldCard, oldCardDisplay, inputCard, inputKey) => {
     const cardPattern = /^[0-9\b ]+$/;

     if (inputCard && inputCard.length > 0) {
         if (!cardPattern.test(inputCard)) {
             return {
               cardNumber: oldCard,
               cardNumberDisplay: oldCardDisplay,
             };
         }

         let cardNumber = inputCard.replace(/ /g, "");

         if (inputKey === 8 && cardNumber === oldCard) { // if it is deleting space
             cardNumber = cardNumber.slice(0, -1);
         }

         let displayCard = "";
         for (let i = 0; i < cardNumber.length; i++) {
             displayCard += cardNumber.charAt(i);
             if (i % 4 === 3 && i > 0) {
                 displayCard += " ";
             }
         }
         const cardType = getCardType(cardNumber);
         const newInfo = {
            cardNumber: cardNumber,
            cardNumberDisplay: displayCard,
            cardType: cardType
        }
        if (cardType === CardType.DINERS_CLUB) {
            return {
               ...newInfo,
               cvv: ''
            }
        } else {
            return newInfo;
        }
     } else {
          return {
             cardNumber: oldCard,
             cardNumberDisplay: inputCard,
           };
     }
}

export const isValidExpiry = (month, year) => {
    const parsedMonth = parseInt(month.toString());
    const parsedYear = parseInt(year.toString());
    if (!(parsedMonth && parsedYear)) {
        return false;
    }

    if (parsedMonth < 1 || parsedMonth > 12) {
        return false;
    }

    const cur = new Date();
    const expiryDate = new Date(2000 + parsedYear, parsedMonth - 1);
    const currentDate = new Date(cur.getFullYear(), cur.getMonth());

    return expiryDate >= currentDate;

}

export const isValidCardNumber = (cardNumber: string) : boolean => {
    try {
        return isValid(cardNumber);
    } catch (error) {
        return false;
    }
}

export const isValidCVV = (cardNumber: string, cvv: string): boolean => {
    const cardType = getCardType(cardNumber);
    return cardType === CardType.DINERS_CLUB || (cvv != null && cvv.toString().length > 0);
}

export const isValidName = (cardName: string): boolean => {
    return cardName != null && cardName.toString().length > 0
}

export const getCardType = (cardNumber) => {
    const visaPattern = /^4[0-9]{6,}$/;
    const masterPattern = /^5[1-5][0-9]{5,}$|^222[1-9][0-9]{3,}$|^22[3-9][0-9]{4,}$|^2[3-6][0-9]{5,}$|^27[01][0-9]{4,}$|^2720[0-9]{3,}$/;
    const dinersCardPattern = /^3(?:0[0-5]|[68][0-9])[0-9]{4,}$/;
    const aeCardPattern = /^3[47][0-9]{5,}$/;
    if (dinersCardPattern.test(cardNumber)) {
        return CardType.DINERS_CLUB;
    } else if (visaPattern.test(cardNumber)) {
        return CardType.VISA;
    } else if (masterPattern.test(cardNumber)) {
        return CardType.MASTERCARD;
    } else if (aeCardPattern.test(cardNumber)) {
        return CardType.AMERICAN_EXPRESS;
    }else {
        return null;
    }
}

/*
** For test card number: http://docs.dev.paymark.nz/click/#test-card-details
*/
export const validCardDetails = (expiryMonth, expiryYear, cardNum, cvv, name) : CardValidation => {
    const validExpiry = isValidExpiry(expiryMonth, expiryYear);
    const validCard =  isValidCardNumber(cardNum);
    const validCvv = isValidCVV(cardNum, cvv);
    const validName = isValidName(name);

    return {
        failed: !(validCard && validExpiry && validCvv && validName),
        invalidExpiry: !validExpiry,
        invalidCreditCard: !validCard,
        invalidCvv: !validCvv,
        invalidName: !validName
        } as CardValidation
}